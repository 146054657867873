import React, { useEffect, useRef, useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { hooks } from 'botframework-webchat-component';
import { makeStyles } from '@material-ui/core/styles';
import Attachment from './Attachment';
import SendBox from './SendBox';
import SuggestedActions from './suggested-actions/SuggestedActions';
import TypingIndicator from './typing-indicator/TypingIndicator';
import Carousel from './carousel/Carousel';
import LoadingIndicatorProvider from '../../services/LoadingIndicatorContext';
import useLastActivity from './hooks/useLastActivity';
import useAccessibility from './hooks/useAccessibility';
import UploadFailed from './UploadFailed';
import { EventList } from './utils/EventList';
import ScrollToBottom from '../../components/ScrollToBottom';
import VehicleDataCard from './adaptive-cards/VehicleDataCard';
import { withClientConfigs } from '../../services/ClientConfigsContextProvider';
import { detect } from 'detect-browser';
import flowRight from 'lodash.flowright';
import { withLanguageData } from '../../services/LanguageDataProvider';
import { filterUndoActivities, getImageLocationUrl, getFilteredActivities } from './utils/activity-utils';
import SendBoxInputProvider from './SendBoxInput/SendBoxInputProvider';
import { BoxLoading } from '../../components/LoadingSuspense';
import ConversationWrapper from './ConversationWrapper';
import { useClaimFile, useClaimFileLastStepKey } from '../../services/ClaimFileContextProvider';
import { UAParser } from 'ua-parser-js';
import { ClaimStatusContext } from '../../services/ClaimStatusContextProvider';
import { useOauthApiConfigs } from '../../services/TenantOauthContextProvider';
import { login }  from '../../pages/web-chat/Oauth0';
import Cookies from 'js-cookie';

const { useActivities, useScrollToEnd, useSendEvent, useSendMessageBack } = hooks;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: '100%',
    bottom: 0,
  },
  transcriptWrapper: {
    margin: theme.spacing('auto', 3, 0, 3),
  },
  botMessageWrapper: {
    marginBottom: theme.spacing(0.5),
  },
  userMessageWrapper: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
    textAlign: 'right',
  },
  mediaMessageWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
    '&:focus': {
      outline: 'none',
    },
  },
  mapSnapshop: {
    marginTop: '-4px',
    marginBottom: '8px',
    width: '200px',
    height: '200px',
  },
  imageButton: {
    marginTop: '4px',
    marginBottom: '8px',
    width: '160px',
    height: '160px',
  },
  image: {
    width: '100px',
    height: '100px',
  },
  customerLogo: {
    maxWidth: '300px',
    maxHeight: '300px',
    height: '100px',
    objectFit: 'contain',
  },
  customerLogoContainer: {
    padding: '24px 0px',
  },
  vehicleDataWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
  loadingIndicatorWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageFromBot: {
    maxWidth: '180px',
    maxHeight: '108px',
    marginBlock: '4px',
    marginLeft: '32px',
  },
}));

const WebChat = ({
  lineOfBusiness,
  clientConfigs,
  setClientConfigs,
  isContinue,
  setLanguageData,
  allActivitiesCount,
  continueLoadingMessage,
  isAuthenticated,
  isLoadingPolicyDetails
}) => {
  const [activities] = useActivities();
  const { setContextValue: setContextValue, status: claimStatus } = useContext(ClaimStatusContext);
  const scrollToEnd = useScrollToEnd();
  const sendEvent = useSendEvent();
  const sendBoxContainerRef = useRef(null);
  const classes = useStyles({ clientConfigs });
  const [eventsCount, setEventsCount] = useState(1);
  const [loadingSpinnerPolicyDetails, setLoadingSpinnerPolicyDetails] = useState(isLoadingPolicyDetails);
  const [countedEvents, setCountedEvents] = useState([]);
  const [previousActivitiesCount, setPreviousActivitiesCount] = useState(0);
  const oauthConfigs = useOauthApiConfigs();
  const setTenantConfigs = useCallback(
    (eventValue) => {
      const configs = JSON.parse(eventValue);
      setClientConfigs(configs);
    },
    [setClientConfigs],
  );
  const incrementEventsCount = useCallback(
    (eventName) => {
      if (isContinue && countedEvents.indexOf(eventName) < 0) {
        setCountedEvents([...countedEvents, eventName]);
        setEventsCount((prevCount) => prevCount + 1);
      }
    },
    [countedEvents, setCountedEvents, isContinue],
  );
  const hiddenFileEvents = [];
  activities
    .filter(({ type, name }) => type === 'event' && name === EventList.HideRemovedFilesEvent)
    .forEach((activity) => {
      const values = JSON.parse(activity.value);
      Array.isArray(values)
        ? values.forEach((val) => hiddenFileEvents.push({ ...activity, value: val }))
        : hiddenFileEvents.push({ ...activity, value: values });
    });

  const claimStepFiles = useClaimFile();
  const lastStepKey = useClaimFileLastStepKey();

  useEffect(() => {
    const lastRenderOrRemoveFilesEventActivity = activities
      .filter(
        ({ type, name }) =>
          type === 'event' && (name === EventList.RenderFilesEvent || name === EventList.RemoveFileEvent),
      )
      .pop();

    if (!lastRenderOrRemoveFilesEventActivity) return;

    const lastFilesUploadedOrSingleFileDeletion = JSON.parse(lastRenderOrRemoveFilesEventActivity.value);

    // Update the state to reflect file uploads or deletions that occur in other browser tabs or devices
    if (Array.isArray(lastFilesUploadedOrSingleFileDeletion) && lastFilesUploadedOrSingleFileDeletion.length >= 1
      && claimStepFiles?.current?.length > 0 && lastStepKey?.current !== '') {

      const currentStepItem = claimStepFiles.current.find(
        (claimStep) => claimStep.stepKey == lastStepKey.current,
      );

      if (!currentStepItem) return;

      const allFilesHaveSameStepKey = lastFilesUploadedOrSingleFileDeletion.every((file) => {
        return (
          file.stepKey === lastStepKey.current
        );
      });

      // Verify if the current step key is correct and if the last event's name is RenderFilesEvent
      if (allFilesHaveSameStepKey && lastRenderOrRemoveFilesEventActivity.name === EventList.RenderFilesEvent) {
        const lastSaveFileEventActivity = activities
          .filter(({ type, name }) => type === 'event' && name === EventList.SaveFilesEvent)
          .pop();

        // Check if the value of the last save file event matches the last render/remove event value
        if (lastSaveFileEventActivity?.value === lastRenderOrRemoveFilesEventActivity.value) {

          // Add each file from `lastFilesUploadedOrSingleFileDeletion` to `currentStepItem.files` if it doesn't already exist
          lastFilesUploadedOrSingleFileDeletion.forEach((newFile) => {
            const fileExists = currentStepItem.files.some(
              (file) => file.id === newFile.id
            );

            if (!fileExists) {
              currentStepItem.files.push(newFile);
            }
          });
        }
      } else if (lastRenderOrRemoveFilesEventActivity.name === EventList.RemoveFileEvent) {
        // Remove the file from the state following a RemoveFileEvent.
        // NOTE: Only one file can be deleted at a time.
        const deletedFileId = lastFilesUploadedOrSingleFileDeletion[0].fileId;
        if (currentStepItem.files.find((file) => file.id === deletedFileId))
          currentStepItem.files = currentStepItem.files.filter((file) => file.id != deletedFileId);
      }
    }
  }, [activities, claimStepFiles, lastStepKey]);

  const addTabIndexToLinks = () => {
    const links = document.getElementsByTagName('a');
    if (links.length > 0) {
      for (let index = links.length - 1; index >= 0; index--) {
        links[index].setAttribute('tabindex', -1); // Accessibility: ignore links from tab order
      }
    }
  };

  useEffect(() => {
    window.onbeforeunload = () => ''; // pop-up modal before close tab
  }, []);

  useEffect(() => {
    scrollToEnd();
    addTabIndexToLinks();
  }, [activities, scrollToEnd]);

  const lastActivity = useLastActivity();
  const lastEvent = useLastActivity('event');

  const lastTypingActivity = useLastActivity('typing');
  const isLastTypingActivity = (activityId) => {
    return lastTypingActivity && lastTypingActivity.id === activityId;
  };

  const isLastFailUpload = (activityId) => {
    const failEvents = activities.filter(
      (x) => x.name === EventList.TryReloadFileOnFailEvent || x.name === EventList.UploadFailedEvent,
    );
    return activityId === failEvents[failEvents.length - 1].id;
  };

  const captureTimezone = useCallback(() => {
    const timezoneOffsetInMinutes = -new Date().getTimezoneOffset();
    sendEvent(EventList.SetupTimezoneEvent, timezoneOffsetInMinutes);
  }, [sendEvent]);

  const sendRequestToCaptureMetadata = useCallback(() => {
    const browserData = detect();

    const screenInfo = {
      isTouchDevice: 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0,
      screenWidth: screen.width,
      screenheight: screen.height,
      screenAvailWidth: screen.availWidth,
      screenAvailHeight: screen.availHeight,
      screenColorDepth: screen.colorDepth,
      screenPixelDepth: screen.pixelDepth,
    };
    const parser = new UAParser(window.navigator.userAgent);
    const deviceData = parser.getResult();

    const fetchWithTimeout = (url, timeout = 1000) => {
      return Promise.race([
        fetch(url),
        new Promise((_, reject) => setTimeout(() => reject(), timeout))
      ]);
    };

    fetchWithTimeout('https://geolocation-db.com/json/')
      .then((r) => r.json())
      .then((data) => {
        incrementEventsCount(EventList.SetupDataEvent);
        sendEvent(EventList.SetupDataEvent, {
          browser: `${browserData.name} ${browserData.version}`,
          device: browserData.os,
          location: data,
          screenInformation: screenInfo,
          deviceInformation: deviceData,
        });
      })
      .catch(() => {
        incrementEventsCount(EventList.SetupDataEvent);
        sendEvent(EventList.SetupDataEvent, {
          browser: `${browserData.name} ${browserData.version}`,
          device: browserData.os,
           //Do not change this, Data factory won't take claims that have location as non-objects
          location: {
            country_code: 'Unknown',
            country_name: 'Unknown',
            city: 'Unknown',
            postal: 'Unknown',
            latitude: 0.0,
            longitude: 0.0,
            IPv4: 'Unknown',
            state: 'Unknown',
          },
          screenInformation: screenInfo,
          deviceInformation: deviceData,
        });
      });
  }, [sendEvent, incrementEventsCount]);

  const RedirectClientToAuthentication = useCallback( async (resumeDetails) => {
      const callbackDetails = {...resumeDetails, "lineOfBusiness": lineOfBusiness };
      Cookies.set('oauth', JSON.stringify(callbackDetails), { secure: true, sameSite: 'Strict', expires: 1 });      
      await login(oauthConfigs);          
  }, [oauthConfigs, lineOfBusiness]);

  useEffect(() => {
    if (lastEvent?.name === EventList.GetClientParamsEvent && !isContinue) {
       const searchParams = new URLSearchParams(location.search);
       const paramsObj = {};
       searchParams.forEach((value, key) => {
         paramsObj[key] = value;
       });

      sendEvent(EventList.SetupClientParamsEvent, paramsObj);
    } else if (lastEvent?.name === EventList.RedirectClientEvent && !isContinue && isAuthenticated !== true) {
      RedirectClientToAuthentication(lastEvent?.value);
    } else if (lastEvent?.name === EventList.GetDataEvent && !isContinue) {
      sendRequestToCaptureMetadata();
    } else if (lastEvent?.name === EventList.GetTimezoneEvent  && !isContinue) {
      captureTimezone();
    } else if (lastEvent?.name === EventList.DownloadFileEvent) {
      if (lastEvent?.value !== undefined) {
        const link = document.createElement('a');
        link.href = lastEvent.value.url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else if (lastEvent?.name === EventList.GetLineOfBusinessEvent && lineOfBusiness && !isContinue) {
      sendEvent(EventList.SetupLineOfBusinessEvent, lineOfBusiness);
    } else if (lastEvent?.name === EventList.SendLanguageData && !isContinue) {
        setLanguageData(lastEvent.value);
    } else if (lastEvent?.name === EventList.LoadingSpinnerPolicyDetailsEvent && !isContinue) {
        setLoadingSpinnerPolicyDetails(lastEvent.value);
    } else if (lastEvent && lastEvent.name === EventList.ClientConfigurationEvent && !!lastEvent.value) {
      setTenantConfigs(lastEvent.value);
      incrementEventsCount(EventList.ClientConfigurationEvent);
    }
  }, [
    lastEvent,
    sendEvent,
    lineOfBusiness,
    setLanguageData,
    setTenantConfigs,
    sendRequestToCaptureMetadata,
    captureTimezone,
    incrementEventsCount,
    isContinue,
    RedirectClientToAuthentication,
    isAuthenticated
  ]);

  const { locationImage } = useAccessibility();
  const { filteredUndoActivities, messageWithUndoIconVisibleId } = filterUndoActivities(activities);

  useEffect(() => {
    if (
      isContinue &&
      activities.length === allActivitiesCount + eventsCount - 1 &&
      previousActivitiesCount === 0
    ) {
      setPreviousActivitiesCount(getFilteredActivities(filteredUndoActivities, isContinue).length);
    }

    if (
      isContinue &&
      activities.length === allActivitiesCount + eventsCount - 1 &&
      lastEvent?.name === EventList.GetDataEvent
    ) {
      sendRequestToCaptureMetadata();
    }
    if (
      lastEvent?.name === EventList.SetClaimStatus &&
      claimStatus.value !== lastEvent?.value.claimStatus &&
      claimStatus.index !== lastEvent?.id
    ) {
      setContextValue({
        value: lastEvent?.value.claimStatus,
        index: lastEvent?.id,
      });
    }
  }, [
    isContinue,
    activities,
    allActivitiesCount,
    eventsCount,
    previousActivitiesCount,
    filteredUndoActivities,
    lastEvent,
    sendRequestToCaptureMetadata,
    claimStatus.value,
    claimStatus.index,
    setContextValue,
    sendEvent
  ]);

  if ((isContinue && activities.length < allActivitiesCount + eventsCount) || loadingSpinnerPolicyDetails === true) {
    return <BoxLoading>{continueLoadingMessage}</BoxLoading>;
  }

  return (
    <SendBoxInputProvider>
      <main className={classes.root}>
        <ScrollToBottom sendBoxContainerRef={sendBoxContainerRef}>
          <ConversationWrapper
            shouldScrollToBottom={isContinue && activities.length === allActivitiesCount + eventsCount}
          >
            <div className={classes.transcriptWrapper}>
              {!!clientConfigs && !!clientConfigs.CustomerLogo && !!clientConfigs.CustomerLogo && (
                <div className={classes.customerLogoContainer}>
                  <img
                    src={clientConfigs.CustomerLogo.ContentUrl}
                    className={classes.customerLogo + ' customer-logo'}
                    alt='Company Logo'
                  />
                </div>
              )}
              {getFilteredActivities(filteredUndoActivities, isContinue)
                .map((activity, activityIndex) => (
                  <React.Fragment key={activity.id || activityIndex}>
                    {!!activity.text && (
                      // We are using the very same component for text message and attachments.
                      // This is because, attachments can also have "text/markdown" or "text/plain" content.
                      // In this case, we prefer to have a single component for both of them.
                      <div
                        className={
                          activity.from.role == 'user' || activity.from.id == 'user'
                            ? classes.userMessageWrapper
                            : classes.botMessageWrapper
                        }
                      >
                        <Attachment
                          activityId={activity.id}
                          activityIndex={activityIndex}
                          from={activity.from.role}
                          fromId={activity.from.id}
                          content={activity.text}
                          contentType={activity.textFormat === 'markdown' ? 'text/markdown' : 'text/plain'}
                          modalProperties={activity.modalProperties}
                          editableKey={activity.editableKey}
                          arrayKey={activity.arrayKey}
                          arrayIndexKey={activity.arrayIndexKey}
                          editType={activity.editType}
                          timeKey={activity.timeKey}
                          dateKey={activity.dateKey}
                          properties={activity?.properties}
                          validationRegex={activity.validationRegex}
                          validationMessage={activity.validationMessage}
                          editLabels={activity.editLabels}
                          isContinue={isContinue}
                          maskType={activity.maskType ? activity.maskType : activity.value?.maskType}
                          isUndoIconVisible={activity.id === messageWithUndoIconVisibleId}
                          utcDateTimeProp={activity.utcDateTimeProp}
                          masktemplate={activity.masktemplate}
                          minLength={activity.minLength}
                          minLengthValidationMsg={activity.minLengthValidationMsg}
                          maxLength={activity.maxLength}
                          maxLengthValidationMsg={activity.maxLengthValidationMsg}
                          keyboardType={activity.keyboardType}
                          previousActivitiesCount={previousActivitiesCount}
                          hiddenIconsStatuses={activity.hiddenIconsStatuses}
                        />
                      </div>
                    )}
                    {!!activity.name && activity.name === EventList.TryReloadFileOnFailEvent && (
                      <div className={classes.mediaMessageWrapper}>
                        <UploadFailed isLastFailUpload={isLastFailUpload(activity.id)} />
                      </div>
                    )}
                    {!!activity.name && activity.name === EventList.RenderFilesEvent && !!activity.value && (
                      <div className={classes.mediaMessageWrapper}>
                        {JSON.parse(activity.value)
                          .filter((attachment) => !!attachment.id)
                          .filter(({ id }) => !hiddenFileEvents.some(({ value }) => value.fileId === id))
                          .map((attachment, index) => (
                            <React.Fragment key={index}>
                              <Attachment
                                className={classes.image}
                                from={activity.from.role}
                                activityId={activity.id}
                                isContinue={isContinue}
                                maskType={activity.maskType}
                                activityIndex={activityIndex}
                                previousActivitiesCount={previousActivitiesCount}
                                {...attachment}
                              />
                            </React.Fragment>
                          ))}
                      </div>
                    )}
                    {!!activity.name &&
                      activity.name === EventList.ProvideMapSnapshot &&
                      !!activity.value && (
                        <div className={classes.mediaMessageWrapper}>
                          <img
                            className={classes.mapSnapshop}
                            src={getImageLocationUrl(activity, activities)}
                            alt={locationImage}
                          />
                        </div>
                      )}
                    {!!activity.name &&
                      activity.name === EventList.ImageButtonSelectedEvent &&
                      !!activity.value && (
                        <div className={classes.mediaMessageWrapper}>
                          <img
                            className={classes.imageButton}
                            src={activity.value.imageUrl}
                            alt={activity.value.name}
                          />
                        </div>
                      )}
                    {!!activity.name && activity.name === 'SendingAttachmentsFromBot' && (
                      <>
                        {activity.attachments.map((attachment, index) => (
                          <React.Fragment key={index}>
                            <Attachment className={classes.image}
                              activityId={activity.id}
                              activityIndex={activityIndex}
                              previousActivitiesCount={previousActivitiesCount}
                              {...attachment} />
                          </React.Fragment>
                        ))}
                      </>
                    )}
                    {!!activity.name && activity.name === EventList.VehicleData && !!activity.value && (
                      <div className={classes.vehicleDataWrapper}>
                        <VehicleDataCard content={activity.value} />
                      </div>
                    )}
                    {!!activity.name && activity.name === EventList.GuideVideoEvent && (
                         <Attachment
                         {...activity} 
                         from={activity.from.role}
                         contentType={activity.contenttype}/>
                     )}
                    {!!activity.name &&
                      activity.name === EventList.SendImageEvent &&
                      !!activity.value && (
                      <div>
                        <img className={classes.imageFromBot}
                          src={clientConfigs.ClientAssetsUrl + '/' + activity.value}
                        />
                      </div>
                    )}
                    {isLastTypingActivity(activity.id) && <TypingIndicator />}
                  </React.Fragment>
                ))}
            </div>
            {!!lastActivity && !!lastActivity.attachments && lastActivity.attachmentLayout === 'carousel' && (
              <Carousel activity={lastActivity} />
            )}

            <div className={classes.loadingIndicatorWrapper}>
              <LoadingIndicatorProvider>
                <SuggestedActions />
              </LoadingIndicatorProvider>
            </div>
          </ConversationWrapper>
        </ScrollToBottom>
        <SendBox sendBoxContainerRef={sendBoxContainerRef} />
      </main>
    </SendBoxInputProvider>
  );
};

WebChat.propTypes = {
  lineOfBusiness: PropTypes.oneOf(['Home', 'Motor']),
  clientConfigs: PropTypes.object,
  setClientConfigurations: PropTypes.func,
  setClientConfigs: PropTypes.func.isRequired,
  isContinue: PropTypes.bool,
  setLanguageData: PropTypes.func.isRequired,
  continueLoadingMessage: PropTypes.object,
  allActivitiesCount: PropTypes.number,
  isAuthenticated: PropTypes.any,
  isLoadingPolicyDetails: PropTypes.any,
};

const Wrapper = flowRight(withClientConfigs, withLanguageData);

export default Wrapper(WebChat);