import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Components } from 'botframework-webchat-component';
import { createDirectLine } from 'botframework-webchat';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { defaultWebChatTheme } from '../../themes/default-webchat-theme';
import TenantCssProvider from '../../themes/TenantCssProvider';
import WebChat from './WebChat';
import markdownIt from 'markdown-it';
import mdAttrs from 'markdown-it-attrs';
import mdLinkAttributes from 'markdown-it-link-attributes';
import { colorPalette } from 'ri-components';
import { BoxLoading, BoxScreenCenter } from '../../components/LoadingSuspense';
import ClientConfigsContextProvider from '../../services/ClientConfigsContextProvider';
import { useParams } from 'react-router-dom';
import LanguageDataProvider from '../../services/LanguageDataProvider';
import { useErrorHandler } from 'react-error-boundary';
import HeaderImage from './HeaderImage';
import useHttpRequest from '../../hooks/useHttpRequest';
import ClaimFileContextProvider from '../../services/ClaimFileContextProvider';
import { ClaimStatusProvider } from '../../services/ClaimStatusContextProvider';
import Cookies from 'js-cookie';
import { useOauthApiConfigs } from '../../services/TenantOauthContextProvider';
import validateAndParseGuid from '../../utils/guidUtils';
import { useApiConfigs } from '../../services/ApiConfigsContextProvider';
import userLocationUtils from '../../utils/userLocationUtils';
import { encryptMessage } from './utils/encryption';

const { white, coolGrey } = colorPalette;
const md = markdownIt({ html: true });
md.use(mdAttrs);
md.use(mdLinkAttributes, {
  attrs: {
    target: '_blank',
    rel: 'noopener',
  },
});
export const WEB_CHAT_VERTICAL_MARGIN = 40;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: coolGrey[4],
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  webchatPanel: {
    position: 'relative',
    backgroundColor: white,
    height: '100%',
    width: '100%',
    minWidth: '200px',
    boxShadow: '0 0 24px rgba(161, 171, 197, 0.3)',
    [theme.breakpoints.up('md')]: {
      borderRadius: '12px',
      maxWidth: '760px',
      margin: `${WEB_CHAT_VERTICAL_MARGIN}px auto`,
    },
  },
  loading: {
    margin: theme.spacing(2.5),
  },
}));
// Set default loading indicator message per language
const resumeLoadingMessage = {
  en: <p>Resuming your claim. I&apos;m just getting the details.</p>,
  nl: <p></p>,
};

const resumeErrorMessage = {
  en: (
    <p>
      Sorry, we are unable to load your claim.
    </p>
  )
};

const LoadingPolicyDetails = {
  en: <p>We are just retrieving your details, please wait...</p>,
  nl: <p></p>,
};

const AuthenticationCallBackContinueWebChat = ({ lineOfBusiness }) => {
  const classes = useStyles();
  const [directLine, setDirectLine] = useState();
  const [callbackDone, setCallbackDone] = useState(false);
  const [continueErrorMsg, setContinueErrorMsg] = useState('');
  const { resumeCodeId } = useParams();
  const handleError = useErrorHandler();
  const { sendRequest } = useHttpRequest();
  const oauthConfigs = useOauthApiConfigs();
  const [callBackLineOfBusiness, setCallBackLineOfBusiness] = useState(lineOfBusiness);
  const [allActivitiesCount, setActivitiesCount] = useState(-1);
  const [userIp, setUserIP] = useState();
  const fullLanguage = new URLSearchParams(window.location.search).get('lang') ?? 'en';
  const { webApiUrl } = useApiConfigs();
  const language =
    fullLanguage.indexOf('-') == -1 ? fullLanguage : fullLanguage.substring(0, fullLanguage.indexOf('-'));
  const [encryptedAuthCode, setEncryptedAuthCode] = useState();
  const [encryptedVerifiedCode, setEncryptedVerifiedCode] = useState();
  const [isInitialJourney, setIsInitialJourney] = useState();

  const getCookie = (name) => {
    return Cookies.get(name);
  };

  const removeCookie = (name) => {
    Cookies.remove(name, { secure: true, sameSite: 'Strict' });
  };

  useEffect(() => {
      const saveAuthToken = async () => {
        const urlParams = new URLSearchParams(window.location.search);

        const code = urlParams.get('code');
        const encryptedCode = encryptMessage(code, oauthConfigs.publicKey);

        const codeVerifier = getCookie('codeVerifier');
        removeCookie('codeVerifier');

        const encryptedVerified = encryptMessage(codeVerifier, oauthConfigs.publicKey);

        setEncryptedAuthCode(encryptedCode);
        setEncryptedVerifiedCode(encryptedVerified);

        window.history.replaceState({}, document.title, '/');
        setCallbackDone(true);
    }

    saveAuthToken();
    const isResumeJourney = getCookie('isResumeJourney');
    
    if(isResumeJourney !== undefined ){
      removeCookie('isResumeJourney');
      setIsInitialJourney(false);
    }else{
      setIsInitialJourney(true);
    }

  }, [oauthConfigs]);

  useEffect(() => {
    userLocationUtils.getUserIPAddress()
      .then(result => setUserIP(result));
  }, [setUserIP]);

  useEffect(() => {

    if (!directLine && userIp !== undefined && callbackDone === true) {
      let requestResumeCodeId = resumeCodeId;
      let callbackDetails = JSON.parse(Cookies.get('oauth'));
      if (callbackDetails) {
        requestResumeCodeId = callbackDetails.resumeCodeId;
        setCallBackLineOfBusiness(callbackDetails.lineOfBusiness);
      }

      requestResumeCodeId = validateAndParseGuid(requestResumeCodeId);

      if (requestResumeCodeId === null) {
        setContinueErrorMsg(resumeErrorMessage[language]);
      } else {
        axios.post(`${webApiUrl}/api/resume/validate`, {
          id: requestResumeCodeId,
          ip: userIp
        }).then((validateResult) => {
          if (validateResult?.data?.success) {
            axios.post(`api/bot-config/continue`, {
              id: requestResumeCodeId,
              authenticationCode : encryptedAuthCode,
              codeVerifier : encryptedVerifiedCode,
            }).then((continueResult) => {
              setActivitiesCount(continueResult?.data?.allActivitiesCount);
              setDirectLine(createDirectLine({ token: continueResult.data.token }));
            }).catch(() => setContinueErrorMsg(resumeErrorMessage[language]));
          } else {
            setContinueErrorMsg(resumeErrorMessage[language]);
          }
        }).catch(() => setContinueErrorMsg(resumeErrorMessage[language]));
      }
      }
  }, [encryptedAuthCode, encryptedVerifiedCode, language, directLine, resumeCodeId, handleError, setCallBackLineOfBusiness, userIp, webApiUrl, callbackDone]);
  useEffect(() => {
    sendRequest({ url: '/api/client-config/client-assets-url' });
  }, [sendRequest]);
  const errorOrLoading = () => {
    if (continueErrorMsg) return <BoxScreenCenter>{continueErrorMsg}</BoxScreenCenter>;
    if (!directLine) return <BoxLoading>{resumeLoadingMessage[language]}</BoxLoading>;
  };
  return (
    <ThemeProvider theme={defaultWebChatTheme}>
      <div className={classes.root} id='chat-background-outside'>
        <div className={classes.webchatPanel}>
          {errorOrLoading() || (
            <Components.Composer directLine={directLine} renderMarkdown={md.render.bind(md)}>
              <ClaimStatusProvider>
                <ClientConfigsContextProvider>
                  <TenantCssProvider />
                  <LanguageDataProvider>
                    <ClaimFileContextProvider>
                      <HeaderImage />
                      {isInitialJourney === true ? (
                        <WebChat isAuthenticated={true} lineOfBusiness={callBackLineOfBusiness} continueLoadingMessage={LoadingPolicyDetails[language]}/>
                      ) : (
                        <WebChat
                          isContinue
                          allActivitiesCount={allActivitiesCount}
                          continueLoadingMessage={resumeLoadingMessage[language]}
                        />
                      )}
                    </ClaimFileContextProvider>
                  </LanguageDataProvider>
                </ClientConfigsContextProvider>
              </ClaimStatusProvider>
            </Components.Composer>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};
export default AuthenticationCallBackContinueWebChat;
AuthenticationCallBackContinueWebChat.propTypes = {
  lineOfBusiness: PropTypes.oneOf(['Home', 'Motor']),
};